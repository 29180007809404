.getting-started {
    list-style: none;
    padding: 0;
    li {
        padding-top: 15px;
    }
    i {
        padding-right: 10px;
    }
}

#help-intro {
    margin-bottom: 0;
    li {
        padding: 5px;
    }
}

.profile-info {
    margin-top: 6px !important;
}

.profile-ul {
    margin-bottom: 6px !important;
}

.main-ul {
    margin-top: -1px !important;
}

.folder-paths li {
    color: rgb(10, 110, 189) !important;
}

.breadcrumb {
    padding-left: 0px !important;
}

.cl {
    height: 45px;
    margin-top: -11px;
}

.cl-center {
    position: absolute !important;
    height: 45px;
    margin: auto !important;
    top: 0 !important;
    left: 0 !important;
    bottom: 0 !important;
    right: 0 !important;

    @media (min-width: 768px) and (max-width: 991px) {
        display: none !important;
    }
    @media (min-width: 992px) and (max-width: 1199px) {
        display: none !important;
    }
    @media (min-width: 1200px) {
        display: none !important;
    }
}

.help-block-error {
    display: none !important;
}

.label-totals {
    float: right;
}

.dropdown-menu {
    z-index: 15;
}

.help-topic-header {
    font-weight: normal;
    font-size: 17px;
}

input#layout:disabled {
    color: black !important;
    background-color: rgb(255, 255, 255) !important;
}